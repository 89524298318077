// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";
import "slick-carousel";

// custom

jQuery(document).ready(function () {

    jQuery(".related .btn-product-related-more-info").attr("style","display:inline-block");

    jQuery(".related .btn-product-more-info").remove();
    jQuery(".related .related-short-description").remove();
    jQuery(".related .realted_catename").remove();
    jQuery(".related .single-product-btn-cus").remove();

    jQuery(".main-navigation").before("<div class=\"mobile-trigger\"><i></i></div>");

    jQuery(".mobile-trigger").on("click", function () {
        jQuery(this).toggleClass("active");
        jQuery("body").toggleClass("mobile-open");
    });

    jQuery(document).on("click touchstart",function (event) {
        if (jQuery(event.target).parents(".header-top").length < 1 && !jQuery(event.target).hasClass("header-top"))
        {
            jQuery("body").removeClass("mobile-open");
        }
    });

    jQuery(".slider-section").slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed:8000,
        delay: 2000,
    });

    jQuery(".product-item").slick({

        infinite: true,
        autoplay: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true
                }
            },

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    });
});

const equalheightrow = function (container) {
    let currentTallest = 0;
    let currentRowStart = 0;
    const rowDivs = new Array();
    let jQueryel;
    jQuery(container).each(function () {
        jQueryel = jQuery(this);
        jQuery(jQueryel).height("auto");
        const topPostion = jQueryel.offset().top;

        if (currentRowStart != topPostion) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = jQueryel.height();
            rowDivs.push(jQueryel);
        } else {
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
        }
        for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
};

jQuery(window).on("load", function () {

    const actionClone = jQuery(".schedule").clone();
    jQuery(".main-navigation").append(actionClone);

    setTimeout(function () {
        equalheightrow(".production-item .item-inner");
        equalheightrow(".product-item .item-inner");
    },500);

});

jQuery(window).on("resize", function () {

    setTimeout(function () {
        equalheightrow(".production-item .item-inner");
        equalheightrow(".product-item .item-inner");
    },500);
});
